<template>
    <ErrorRenderer v-if="capturedError" />
    <Teleport :to="editorContainer" v-else :disabled="!expandedPopup">
        <BodyWrapper :enabled="expandedPopup">
            <component v-if="editor.isSlot" :is="editor.node" :modelValue="row" :row="row" :column="column" :rowIndex="rowIndex"
                :class="column.editorClass" ref="editorRef" v-bind="column.cellEditorParams">
            </component>
            <component v-else :is="editor.node" v-model="row[column.field]" :class="column.editorClass" ref="editorRef"
                v-bind="column.cellEditorParams, conditionalProps">
            </component>
        </BodyWrapper>
    </Teleport>

    <Teleport v-if="expandedPopup" :to="cellContainer">
        <div ref="editorAnchorRef" class="grid-editor-anchor" :style="anchorStyle"></div>
    </Teleport>
</template>
<script setup lang="ts">
import { InjectionKeys } from 'o365-utils';
import { inject, provide, computed, ref, resolveComponent, h, nextTick, onBeforeUnmount, onUpdated } from 'vue';
import { useErrorCapture } from 'o365-vue-utils';
import { createPopper } from 'popper';

// TODO: Remove after checking its safe
import { OTextEditor, ONumberEditor, OBitEditor, ODateEditor } from 'o365-ui-components';
const InputEditors = { OTextEditor, ONumberEditor, OBitEditor, ODateEditor };

const props = defineProps<{
    row: any,
    column: any,
    rowIndex: number,
    dataGridControl: any,
    gridContainer?: HTMLElement,
}>();

const resolveComponentFromApp = inject('resolveComponent', null);

function activate() {
    if (props.dataGridControl.navigation?.editorActivated) { return; }
    props.dataGridControl.navigation?.activateEditor();
}

const expandedPopup = ref(false);
function activatePopupMode() {
    if (props.dataGridControl?.isTable) { return; }
    expandedPopup.value = true;
    nextTick().then(() => {
        initPopper();
    });
}
function deactivatePopupMode() {
    if (props.dataGridControl?.isTable) { return; }
    expandedPopup.value = false;
    destroyPopper();
}
provide(InjectionKeys.dataGridEditorCellControlKey, {
    activate,
    expandedPopup,
    activatePopupMode,
    deactivatePopupMode
});
provide('is-in-grid-cell', true);

const isTable = props.dataGridControl.isTable;

const editorContainer = computed(() => {
    if (props.row.isNewRecord && !isTable && expandedPopup.value) {
        return props.dataGridControl.container;
    } else {
        return props.gridContainer;
    }
});

const editor = computed(() => {
    deactivatePopupMode();
    if (props.column.cellEditorSlot) {
        return { node: props.column.cellEditorSlot, isSlot: true };
    } else {
        if (typeof props.column.cellEditor === 'string') {
            let editor = InputEditors[props.column.cellEditor];
            let node = editor ?? resolveComponentFromApp(props.column.cellEditor) ?? resolveComponent(props.column.cellEditor);
            return { node: node, isSlot: false };
        } else {
            return { node: props.column.cellEditor, isSlot: false };
        }
    }
});

const editorProps = computed(() => {
    if (typeof editor.value === 'object' && editor.value?.node?.props) {
        if (Array.isArray(editor.value.node.props)) {
            return editor.value.node.props;
        } else {
            return Object.keys(editor.value.node.props);
        }
    } else {
        return [];
    }
});

const conditionalProps = computed(() => {
    const obj: any = {};
    if (editorProps.value) {
        if (editorProps.value.includes('row')) {
            obj.row = props.row;
        }
        if (editorProps.value.includes('column')) {
            obj.column = props.column;
        }
    }
    return obj;
});

const [capturedError, ErrorRenderer] = useErrorCapture({
    consoleMessagee: `Error encountered when trying to render column editor: ${props.column?.colId}`,
    errorRenderFunctionOptions: {
        uiTitleMessage: 'An error has occured when trying to render this cell editor'
    }
});

// --- Expanded Editor Logic ---
const anchorStyle = computed(() => {
    const pos = props.dataGridControl.virtualScrollApi.getPosByIndex(props.rowIndex);

    return {
        'position': 'absolute',
        'left': props.column.left + 'px',
        'transform': `translateY(${pos}px)`,
    }
});

const cellContainer = computed(() => {
    return getCellContainer(props.column.pinned);
});

function getCellContainer(pinned) {
    switch (pinned) {
        case 'left':
            return props.gridContainer?.querySelector('.o365-body-left-pinned-cols');
        case 'right':
            return props.gridContainer?.querySelector('.o365-body-right-pinned-cols');
        default:
            return props.gridContainer?.querySelector('.o365-body-center-cols-container');
    }
};

const editorClass = computed(() => {
    const classes = [{
        'o365-editor-popup': expandedPopup.value,
        'p-2': expandedPopup.value,
        'rounded-0': expandedPopup.value,
        'card': expandedPopup.value,
        'shadow': expandedPopup.value,
        //'o365-cell-range-single-cell': !expandedPopup.value
    }];
    if (props.column.cellClass) {
        classes.push(props.column.cellClass);
    }
    return classes;
});

const style = computed(() => {
    const height = props.dataGridControl.virtualScrollApi.getRowHeightByIndex(props.rowIndex);
    return {
        'min-width': expandedPopup.value ? '400px' : undefined,
        'max-height': expandedPopup.value ? '50vh' : undefined,
        'overflow-y': (expandedPopup.value || isTable) ? 'auto' : undefined,
        'position': 'absolute',
        'width': props.column.width - 1 + props.column.widthAdjustment + 'px',
        //'left': column.value.left + 'px',
        'height': (expandedPopup.value || isTable) ? 'auto' : `${height}px`,
        'z-index': (expandedPopup.value && !isTable) ? '3' : undefined,
        //'transform': `translateY(${pos}px)`,
    }
});

const editorCellRef = ref(null);
const editorAnchorRef = ref(null);

function BodyWrapper(props, { slots }) {
    if (props.enabled) {
        return h('div', {
            class: ['o365-body-cell o365-editor-cell', ...editorClass.value],
            style: style.value,
            ref: el => editorCellRef.value = el,
        }, slots.default());
    } else {
        return slots.default();
    }
}
BodyWrapper.props = { enabled: Boolean };

let popperInstance = null;
function initPopper() {
    if (editorAnchorRef.value == null || editorCellRef.value == null) { return; }
    popperInstance = createPopper(editorAnchorRef.value, editorCellRef.value, {
        placement: 'bottom-start',
        modifiers: [
            {
                name: 'flip',
                enabled: true,
                options: {
                    flipVariations: false,
                    fallbackPlacements: ['top-start']
                }
            },
            {
                name: 'offset',
                options: {
                    offset: ({ placement }) => {
                        if (placement === 'top-start') {
                            return [0, -33];
                        } else {
                            return [];
                        }
                    }
                }
            },
            {
                name: 'preventOverflow',
                options: {
                    mainAxis: false, // true by default
                },
            },
        ],
    });
}

onUpdated(() => {
    if (popperInstance) {
        popperInstance.state.elements.reference = editorAnchorRef.value;
        popperInstance.update();
    }
});

onBeforeUnmount(() => {
    destroyPopper();
});

function destroyPopper() {
    if (popperInstance) {
        popperInstance.destroy();
    }
}

</script>